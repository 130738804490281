import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore } from '@/utils/store-accessor';
import { Validator } from 'vee-validate';
const dict = {
    custom: {
        password: {
            required: 'Senha deve ser preenchido!',
        },
        password_confirmation: {
            required: 'Confirmar Senha deve ser preenchido!',
            confirmed: 'As senhas devem ser iguais!',
        },
    },
};
Validator.localize('pt', dict);
let UserProfileEdit = class UserProfileEdit extends Vue {
    constructor() {
        super(...arguments);
        this.password1 = '';
        this.password2 = '';
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    reset() {
        this.password1 = '';
        this.password2 = '';
        this.$validator.reset();
    }
    cancel() {
        this.$router.back();
    }
    async submit() {
        if (await this.$validator.validateAll()) {
            await mainStore.resetPassword({
                password: this.password1,
                email: this.userProfile?.email,
                user_id: this.userProfile?.id
            });
            this.$router.back();
        }
    }
};
UserProfileEdit = __decorate([
    Component
], UserProfileEdit);
export default UserProfileEdit;
